export default class OrgTableItem {
    orgId = 0;
    orgStatus = 0;
    orgTypeId = 0;
    orgTypeIdName = 0;
    adcode = 0;
    adcodeName = "";
    orgParentId = 0;
    orgParentIdName = "";
    orgParentCode = 0;
    orgParentCodeName = "";
    orgSortId = 0;
    orgCode = "";
    orgName = "";
    orgRemark = "";
    createTime = "";
    createName = "";
    modifyTime = "";
    modifyName = "";
}
